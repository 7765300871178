import Navbar from "../components/partials/Navbar";
import Footer from "../components/partials/Footer";
import HeroSection from "../components/containers/HeroSection";
import Profession from "../components/containers/Profession";
import StepsToBuild from "../components/containers/StepsToBuild";
import WhyConnect from "../components/containers/WhyConnect";
import BecomeBitSqr from "../components/containers/BecomeBitSqr";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { useEffect } from "react";

const Landing = () => {
  const { t } = useTranslation();

  const changlang = (l) => {
    i18n.changeLanguage(l);
    localStorage.setItem("language", l);
  };
  useEffect(() => {
    const currentlanguage: any = localStorage.getItem("language");
    i18n.changeLanguage(currentlanguage);
  }, []);
  return (
    <main className={"flex justify-center"}>
      <section className={"main-line-height"}>
        {/* <Navbar t={t} language={changlang} /> */}

        <div className={"dynamic-x-padding"}>
          <div className={"padding-top-hero-section"}>
            <HeroSection t={t} language={changlang} />
          </div>

          <div className={"dynamic-y-padding  "}>
            <Profession t={t} language={changlang} />
          </div>
        </div>

        <div className={"dynamic-y-padding "}>
          <StepsToBuild t={t} language={changlang} />
        </div>

        <div className={"dynamic-x-padding"}>
          <div className={"dynamic-y-padding"}>
            <WhyConnect t={t} language={changlang} />
          </div>
          <div className={"dynamic-y-padding"}>
            <BecomeBitSqr t={t} language={changlang} />
          </div>
        </div>
      </section>
      <section className="w-full">
        <Footer t={t} language={changlang} />
      </section>
    </main>
  );
};

export default Landing;
